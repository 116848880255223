<template>
  <div class="app-post-image">
    <img :src="imageUrl" alt="" class="app-post-image__background">
    <img :src="imageUrl" alt="" class="app-post-image__image" />
  </div>

</template>
<script>
export default {
  name: "AppPostImage",
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageUrl() {
      return this.media?.url?.url;
    },
    background() {
      return {
        backgroundImage: `url(${this.imageUrl})`,
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.app-post-image {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &__background {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: -1;
    filter: blur(10px);
    transform: scale(4);
    opacity: 0.5;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
    transform: translateY(-50%);
    z-index: 2;
    object-fit: contain;
  }
}
</style>
