<template>
  <div class="video w-100">
    <MuxVideoPlayer :video="media"  v-bind="$attrs" is-flow-view />
  </div>
</template>

<script>
import MuxVideoPlayer from "@/features/ui/common/MuxVideoPlayer.vue";

export default {
  name: "AppPostVideo",
  components: { MuxVideoPlayer },

  props: {
    media: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.video {
  display: contents;
}
</style>
