<template>
  <div
    class="app-post-content-card"
    :class="{ [`app-post-content-card--${type}`]: type }"
  >
    <component :is="card" :media="media"  v-bind="$attrs"/>
  </div>
</template>
<script>
import AppPostVideo from "@/features/containers/post/components/AppPostVideo.vue";
import AppPostImage from "@/features/containers/post/components/AppPostImage.vue";

const mediaType = {
  image: 0,
  video: 1,
};

const renderMediaComponent = {
  [mediaType.image]: "AppPostImage",
  [mediaType.video]: "AppPostVideo",
};

const renderType = {
  message: 'message',
  default: 'default'
}
export default {
  name: "AppPostContentCard",

  components: {
    AppPostImage,
    AppPostVideo,
  },

  props: {
    media: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'default',
      validator(value) {
        return Object.values(renderType).includes(value);
      },
    }
  },

  computed: {
    card() {
      switch (this.media?.type) {
        case mediaType.image:
          return renderMediaComponent[mediaType.image];
        case mediaType.video:
          return renderMediaComponent[mediaType.video];
        default:
          return renderMediaComponent[mediaType.image];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-post-content-card {
  width: 100%;
  height: em(250);
  min-height: em(250);

  &--message {
    height: auto;
    min-height: auto;
  }
}
</style>
