<template>
  <mux-player
    :ref="`player-${videoKey}`"
    :playback-id="videConfig.video"
    :playback-token="videConfig.token"
    :thumbnail-token="videConfig.thumbnailToken"
    :storyboard-token="videConfig.storyboardJwt"
    metadata-video-title="title"
    playsinline
    preload="auto"
    loop
    :style="style"
    @timeupdate="timeupdate"
    class="player"
    :class="{ 'player--flow': isFlowView }"
  />
  <!--  :autoplay="isActive"-->
  <!--  @canplaythrough="canplaythrough"-->
</template>
<script>

const defaultVideoStileConfig = {
  "--media-control-display": "none", // hide bottom control panel
  "--media-object-fit": "cover", // change video style (default contain)
};

export default {
  name: "MuxVideoPlayer",
  props: {
    video: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    styleConfig: {
      type: Object,
      default: () => ({}),
    },
    isActive: {
      type: Boolean,
      default: false,
    },

    isFlowView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    videConfig() {
      const { id: videoId = "", jwt = "" } = this.video?.url;
      const thumbnailToken = this.video?.screenshot?.jwt;
      const storyboardJwt = this.video?.url?.storyboard_jwt;

      return { video: videoId, token: jwt, thumbnailToken, storyboardJwt };
    },
    player() {
      return this.$refs?.[`player-${this.videoKey}`];
    },
  },
  data() {
    return {
      videoKey: Math.random(),
      canPlay: false,
      isPlay: false,
      halfWatched: false,
      style: defaultVideoStileConfig,
    };
  },
  mounted() {
    this.initState();
  },
  beforeDestroy() {
    this.destrouState();
  },
  methods: {
    initState() {
      this.setVideoStyleConfig();
      this.addListeners();
    },

    setVideoStyleConfig() {
      this.style = {
        ...defaultVideoStileConfig,
        ...this.styleConfig,
      };
    },

    destrouState() {
      this.player?.removeEventListener("canplay", this.onCanplay);
      this.player?.removeEventListener("play", this.onPlay);
      this.player?.removeEventListener("pause", this.onPause);
    },

    addListeners() {
      this.unwatchIsLiveProp = this.$watch("isActive", (newVal) => {
        if (newVal && this.isActive && !this.isPlay) {
          this.play();
          this.resetPlayback();
        } else {
          this.pause();
        }
      });

      this.player?.addEventListener("canplay", this.onCanplay);
      this.player?.addEventListener("play", this.onPlay);
      this.player?.addEventListener("pause", this.onPause);
    },

    onCanplay() {
      this.canPlay = true;
      if (this.isActive && !this.isPlay) {
        this.player.play();
        this.isPlay = true;
      }
    },

    onPlay() {
      this.$emit("video-play");
      this.play();
      this.isPlay = true;
    },
    onPause() {
      this.isPlay = false;
      this.pause();
      this.$emit("video-stop");
    },

    timeupdate() {
      const video = this.player;
      const halfwayPoint = video.duration / 2;

      if (!this.halfWatched && video.currentTime >= halfwayPoint) {
        this.halfWatched = true;
        this.$emit("half-watched");
      }
    },
    canplaythrough() {
      this.player?.click();

      if (this.isActive && !this.isPlay) {
        this.play();
        this.isPlay = true;
      }
    },
    play() {
      this.player?.play();
    },

    pause() {
      this.player?.pause();
      this.isPlay = false;
    },

    resetPlayback() {
      const video = this.player;
      if (!video) return;

      video.currentTime = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vendors/_variables.scss";
.player {
  height: 100%;

  &--post {
    @include media-breakpoint-up(lg) {
      height: 600px !important;
    }

    @include media-breakpoint-up(xl) {
      height: 792px !important;
    }

    @include media-breakpoint-up(sm) {
      height: 500px !important;
    }
  }
}
</style>
